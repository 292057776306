/**
 * Wellplans reducer
 */

import { combineReducers } from 'redux'
import { unionBy, filter } from 'lodash'
import {
  LOAD_WELLPLANS,
  LOAD_WELLPLANS_SUCCESS,
  LOAD_WELLPLANS_ERROR,
  DELETE_WELLPLAN,
  LOAD_WELLPLAN_SUCCESS,
} from './wellplans.constans'

const list = (state = [], action) => {
  switch (action.type) {
    case DELETE_WELLPLAN:
      return filter(state, wellPlan => wellPlan.id !== action.wellPlanId)
    case LOAD_WELLPLANS_SUCCESS:
      return action.wellplans
    case LOAD_WELLPLAN_SUCCESS:
      return unionBy(state, [...state, action.wellplan], 'id')
    default:
      return state
  }
}

const loading = (state = false, action) => {
  switch (action.type) {
    case LOAD_WELLPLANS:
      return true
    case LOAD_WELLPLANS_SUCCESS:
      return false
    case LOAD_WELLPLANS_ERROR:
      return false
    default:
      return state
  }
}

export default combineReducers({
  list,
  loading,
})
