/**
 * Wellplans constants
 */

export const LOAD_WELLPLANS = 'LOAD_WELPLANS'
export const LOAD_WELLPLANS_SUCCESS = 'LOAD_WELLPLANS_SUCCESS'
export const LOAD_WELLPLANS_ERROR = 'LOAD_WELLPLANS_ERROR'

export const DELETE_WELLPLAN = 'DELETE_WELLPLAN'

export const LOAD_WELLPLAN = 'LOAD_WELLPLAN'
export const LOAD_WELLPLAN_SUCCESS = 'LOAD_WELLPLAN_SUCCESS'
export const LOAD_WELLPLAN_ERROR = 'LOAD_WELLPLAN_ERROR'
