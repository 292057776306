import React, { useState } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import initStore from 'redux/createStore'
import { SnackbarProvider } from 'notistack'
import { ContextWrapper } from '@yggdrasil/themes'
import { UnitsContextWrapper } from '@yggdrasil/units'

import NavigationSetup from 'pages'
import { GlobalStyle } from 'common/globalStyle'
import { SidebarContext } from '@yggdrasil/wellplan'
import { LoadingZone } from '@yggdrasil/common'
import * as Sentry from '@sentry/browser'
import ReactGA from 'react-ga'

// This part is for initializing google analytics tracking code and setting the current page
ReactGA.initialize('UA-49462540-2', { debug: true })
ReactGA.pageview(window.location.pathname + window.location.search)

// This part is for connecting the application to the sentry service for error reporting
Sentry.init({ dsn: 'https://91efcc7c148c418dbf867de20b004b7c@sentry.io/1856363' })
const store = initStore()

const App = () => {
  const [showButton, setShowButton] = useState(true)
  const [openSidebar, setOpenSidebar] = useState(true)

  return (
    <Provider store={store}>
      <LoadingZone>
        <Router>
          <ContextWrapper>
            <UnitsContextWrapper>
              <SidebarContext.Provider value={{ showButton, setShowButton, openSidebar, setOpenSidebar }}>
                <SnackbarProvider anchorOrigin={{ horizontal: 'center', vertical: 'top' }} autoHideDuration={3000}>
                  <>
                    <GlobalStyle />
                    <NavigationSetup />
                  </>
                </SnackbarProvider>
              </SidebarContext.Provider>
            </UnitsContextWrapper>
          </ContextWrapper>
        </Router>
      </LoadingZone>
    </Provider>
  )
}

export default App
