import { applyMiddleware, compose } from 'redux'

export default function createMiddleware(clientMiddleware) {
  const bindMiddleware = middleware => {
    if (process.env.NODE_ENV !== 'production' && process.browser) {
      // eslint-disable-next-line global-require
      const { createLogger } = require(`redux-logger`)
      const logger = createLogger({
        // predicate: (getState, action) => true,
        collapsed: (getState, action, logEntry) => !logEntry.error,
      })
      middleware.push(logger)
    }
    return applyMiddleware(...middleware)
  }

  /* eslint-disable no-underscore-dangle */
  const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose
  /* eslint-enable */

  return composeEnhancers(bindMiddleware(clientMiddleware))
}
