import { createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'

import createMiddleware from './utils/reduxMiddleware'
import rootReducer from './reducers'
import rootSaga from './sagas'

const initStore = (initialState = {}) => {
  const sagaMiddleware = createSagaMiddleware()
  const middleware = createMiddleware([sagaMiddleware])
  const store = createStore(rootReducer, initialState, middleware)
  sagaMiddleware.run(rootSaga)
  return store
}

export default initStore
