import React from 'react'
import loadable from '@loadable/component'
import { Switch, Route } from 'react-router-dom'
import PrivateRoute from 'components/PrivateRoute'
import { PROFILE, PROJECT_LIST, LOGIN_URL, SETTINGS, PROJECT_ID, WELL_PLAN_ID } from 'common/urlConstants'
import { WithTracker } from '@yggdrasil/common'

const WellPlanPage = loadable(() => import('pages/well-plan'))
const Projects = loadable(() => import('pages/projects'))
const Project = loadable(() => import('pages/project'))
const Profile = loadable(() => import('pages/profile'))
const UserSettingsPage = loadable(() => import('pages/userSettings'))
const Login = loadable(() => import('pages/login'))
const NotFound = loadable(() => import('pages/404'))
// const WellTrajectory = loadable(() => import('pages/well-trajectory'))
// const DrillstringPage = loadable(() => import('pages/drillstring'))
// const DrillstringListPage = loadable(() => import('pages/drillstring-list'))
// const Casing = loadable(() => import('pages/casing'))
// const Simulation = loadable(() => import('pages/simulation'))
// const FluidDetails = loadable(() => import('pages/fluid-details'))

const NavigationSetup = () => {
  return (
    <Switch>
      <Route path={`${SETTINGS}`} component={WithTracker(UserSettingsPage)} />
      <Route path={`${LOGIN_URL}`} component={WithTracker(Login)} />
      <PrivateRoute exact path={PROJECT_LIST} component={WithTracker(Projects)} />
      <PrivateRoute exact path={`${PROJECT_LIST}/:${PROJECT_ID}`} component={WithTracker(Project)} />
      <PrivateRoute path={`${PROJECT_LIST}/:${PROJECT_ID}/:${WELL_PLAN_ID}`} component={WithTracker(WellPlanPage)} />
      <PrivateRoute path={PROFILE} component={WithTracker(Profile)} />
      <Route component={WithTracker(NotFound)} />
    </Switch>
  )
}

export default NavigationSetup
