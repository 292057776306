/**
 * Wellplans actions
 */

import {
  LOAD_WELLPLANS,
  LOAD_WELLPLANS_SUCCESS,
  LOAD_WELLPLANS_ERROR,
  DELETE_WELLPLAN,
  LOAD_WELLPLAN,
  LOAD_WELLPLAN_ERROR,
  LOAD_WELLPLAN_SUCCESS,
} from './wellplans.constans'

export const getWellplans = projectId => ({
  type: LOAD_WELLPLANS,
  projectId,
})

export const getWellplansSuccess = wellplans => ({
  type: LOAD_WELLPLANS_SUCCESS,
  wellplans,
})

export const getWellplansError = error => ({
  type: LOAD_WELLPLANS_ERROR,
  error,
})

export const deleteWellPlan = wellPlanId => ({
  type: DELETE_WELLPLAN,
  wellPlanId,
})

/**
 * WellPlan
 */
export const getWellPlan = wellPlanId => ({
  type: LOAD_WELLPLAN,
  wellPlanId,
})

export const getWellplanSuccess = wellplan => ({
  type: LOAD_WELLPLAN_SUCCESS,
  wellplan,
})

export const getWellplanError = error => ({
  type: LOAD_WELLPLAN_ERROR,
  error,
})
