import { all, fork } from 'redux-saga/effects'

import projectsSaga from './projects/projects.saga'
import wellplansSaga from './wellplans/wellplans.saga'

function* rootSaga() {
  yield all([fork(projectsSaga), fork(wellplansSaga)])
}

export default rootSaga
