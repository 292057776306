/**
 * Wellplan saga
 */

import { takeLatest, call, put } from 'redux-saga/effects'
import { httpCore } from '@yggdrasil/http-core'

import { LOAD_WELLPLANS, LOAD_WELLPLAN } from './wellplans.constans'

import { getWellplansSuccess, getWellplansError, getWellplanSuccess, getWellplanError } from './wellplans.actions'

export function* getWellplansSaga({ projectId }) {
  try {
    let wellplans = yield call(httpCore.getWellPlanList, { projectId })
    wellplans = wellplans.map(plan => ({ projectId, ...plan }))
    yield put(getWellplansSuccess(wellplans))
  } catch (error) {
    yield put(getWellplansError(error))
  }
}

export function* getWellplanSaga({ wellPlanId }) {
  try {
    const wellplan = yield call(httpCore.getWellPlan, wellPlanId)
    yield put(getWellplanSuccess(wellplan))
  } catch (error) {
    yield put(getWellplanError(error))
  }
}

export default function* defaultSaga() {
  yield takeLatest(LOAD_WELLPLANS, getWellplansSaga)
  yield takeLatest(LOAD_WELLPLAN, getWellplanSaga)
}
