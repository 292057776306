import _defineProperty from "/home/hassanin/Kelda Projects/yggdrasil-frontend/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "/home/hassanin/Kelda Projects/yggdrasil-frontend/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import _toConsumableArray from "/home/hassanin/Kelda Projects/yggdrasil-frontend/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/**
 * This file defines custom texture generators to be used with the `textures` library.
 * Each function is a `number => string` function that returns a path given the `size`
 */
import textures from 'textures';
import { forEach } from 'lodash';
/**
 * @enum
 */

export var TextureType = {
  CUSTOM: 'custom',
  LINE: 'line',
  CIRCLE: 'circle'
};
/**
 * Generates a brick texture
 *
 * @param s the size
 * @returns string the path
 */

var bricks = function bricks(s) {
  var slope = s / 10;
  return "M 0 0 L ".concat(s, " 0 L ").concat(s - slope, " ").concat(s / 2, " M ").concat(s, " ").concat(s / 2, " L 0 ").concat(s / 2, " M ").concat(s / 2, " ").concat(s / 2, " L ").concat(s / 2 - slope, " ").concat(s);
};

var GENERATORS = {
  bricks: bricks
};
var IGNORE_KEYS = new Set(['id']);

var applyProps = function applyProps(gen, props) {
  var out = gen;
  forEach(props, function (value, key) {
    try {
      if (IGNORE_KEYS.has(key)) {
        return;
      }

      if (Array.isArray(value)) {
        out = gen[key].apply(gen, _toConsumableArray(value));
      } else {
        out = gen[key](value);
      }
    } catch (e) {
      var newError = Error("Error while applying prop ".concat(key, " with value ").concat(value, ": ").concat(e.message, "\ngen is ").concat(JSON.stringify(gen), ", props is ").concat(JSON.stringify(props)));
      newError.stack += "\nCaused by: ".concat(e.stack);
      throw newError;
    }
  });
  return out;
};

var generateCustom = function generateCustom(_ref) {
  var path = _ref.path,
      others = _objectWithoutProperties(_ref, ["path"]);

  var d = GENERATORS[path] ? GENERATORS[path] : function () {
    return path;
  };
  var generator = applyProps(textures.paths(), _objectSpread({}, others, {
    d: d
  }));
  return function () {
    return generator;
  };
};
/**
 *
 * @param {TextureType} type
 * @param others
 */


var generatePattern = function generatePattern(_ref2) {
  var type = _ref2.type,
      others = _objectWithoutProperties(_ref2, ["type"]);

  try {
    switch (type) {
      case TextureType.CUSTOM:
        return generateCustom(others);

      default:
        {
          var generator = applyProps(textures[type](), _objectSpread({}, others));
          return function () {
            return generator;
          };
        }
    }
  } catch (e) {
    var newError = Error("Error while generating texture of type ".concat(type, ": ").concat(e.message));
    newError.stack += "\nCaused by: ".concat(e.stack);
    throw newError;
  }
};

export { generatePattern };