// URLs
export const ROOT = '/'
export const LOGIN_URL = '/login'

// Project
export const PROJECT_LIST = '/projects'
export const SETTINGS = '/settings'
export const PROFILE = '/profile'

// Params iDs
export const PROJECT_ID = 'projectId'
export const WELL_PLAN_ID = 'wellPlanId'
