import { decode } from 'jsonwebtoken'
import { httpCore, PersistManager } from '@yggdrasil/http-core'

/**
 * Return Object with two jeneral actions over user session
 */
const useAuth = () => {
  const signIn = accessToken => {
    PersistManager.setAccessToken(accessToken)
    httpCore.updateHeadersWithAccesToken(accessToken)
  }

  const signOut = () => {
    PersistManager.setAccessToken()
    httpCore.updateHeadersWithAccesToken()
  }

  return { signIn, signOut }
}

/**
 * Several helpers functions
 */
export const isSignedIn = () => {
  const token = PersistManager.getAccessToken()
  return !!token
}

export const isTokenExpired = () => {
  const token = PersistManager.getAccessToken()
  const decoded = decode(token)
  if (decoded != null) {
    const { exp } = decoded
    if (exp * 1000 < new Date().getTime()) {
      return true
    }

    return false
  }

  return true
}

export default useAuth
