/**
 * Projects actions
 */

import {
  LOAD_PROJECTS,
  LOAD_PROJECTS_SUCCESS,
  LOAD_PROJECTS_ERROR,
  LOAD_PROJECT,
  LOAD_PROJECT_SUCCESS,
  LOAD_PROJECT_ERROR,
} from './projects.constants'

/**
 * PROJECTS
 */
export const getProjects = () => ({
  type: LOAD_PROJECTS,
})

export const getProjectsSuccess = projects => ({
  type: LOAD_PROJECTS_SUCCESS,
  projects,
})

export const getProjectsError = error => ({
  type: LOAD_PROJECTS_ERROR,
  error,
})

/**
 * PROJECT
 */
export const getProject = projectId => ({
  type: LOAD_PROJECT,
  projectId,
})

export const getProjectSuccess = project => ({
  type: LOAD_PROJECT_SUCCESS,
  project,
})

export const getProjectError = error => ({
  type: LOAD_PROJECT_ERROR,
  error,
})
