/**
 * Projects reducer
 */

import { combineReducers } from 'redux'
import { LOAD_PROJECTS, LOAD_PROJECTS_SUCCESS, LOAD_PROJECTS_ERROR, LOAD_PROJECT_SUCCESS } from './projects.constants'

const list = (state = [], action) => {
  switch (action.type) {
    case LOAD_PROJECT_SUCCESS:
      return [...state, action.project]
    case LOAD_PROJECTS_SUCCESS:
      return action.projects
    default:
      return state
  }
}

const error = (state = false, action) => {
  switch (action.type) {
    case LOAD_PROJECTS_ERROR:
      return action.error
    case LOAD_PROJECTS_SUCCESS:
      return false
    default:
      return state
  }
}

const loading = (state = false, action) => {
  switch (action.type) {
    case LOAD_PROJECTS:
      return true
    case LOAD_PROJECTS_SUCCESS:
      return false
    case LOAD_PROJECTS_ERROR:
      return false
    default:
      return state
  }
}

export default combineReducers({
  list,
  error,
  loading,
})
