import { takeLatest, call, put } from 'redux-saga/effects'
import { httpCore } from '@yggdrasil/http-core'

import { LOAD_PROJECTS, LOAD_PROJECT } from './projects.constants'
import { getProjectsError, getProjectsSuccess, getProjectSuccess, getProjectError } from './projects.actions'

export function* getProjectsSaga() {
  try {
    const projects = yield call(httpCore.getProjectList, null)
    yield put(getProjectsSuccess(projects))
  } catch (error) {
    yield put(getProjectsError(error))
  }
}

export function* getProjectSaga({ projectId }) {
  try {
    const project = yield call(httpCore.getProject, { projectId })
    yield put(getProjectSuccess(project))
  } catch (error) {
    yield put(getProjectError(error))
  }
}

export default function* defaultSaga() {
  yield takeLatest(LOAD_PROJECT, getProjectSaga)
  yield takeLatest(LOAD_PROJECTS, getProjectsSaga)
}
